.title-padder {
  padding: $title-padding;
}

h1.title {
  @extend .header-txt-shadow;
  padding: 0.5em 0;
}

.subtitle {
  font-weight: normal;
  margin-top: 5px;
  text-shadow: 1px 1px 2px $text-shadow;
}

//Shared css in _post.scss


/* --- Aligner --- */
/* within page/posts */

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 50%;

  img {
    width: 100%;
    min-width: 250px;
  }

  .single {
    width: 50%;
    display: block;
  }
}
